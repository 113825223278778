export const getAdminTicketsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_ADMIN_TICKETS':
      return {
        loading: false,
        tickets: action.payload
      }
    case 'GET_ADMIN_TICKETS_FAIELD':
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const createAdminTicketReducer = (state = {}, action) => {
  switch (action.type) {
    case 'ADMIN_CREATE_TICKET':
      return {
        data: action.payload
      }
    case 'ADMIN_CREATE_TICKET_FAILED':
      return {
        error: action.payload
      }
    default:
      return state
  }
}

export const getSingleTicketDetalsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_SINGLE_TICKET_DETAILS':
      return {
        singleTicket: action.payload
      }
    case 'GET_SINGLE_TICKET_DETAILS_FAILED':
      return {
        error: action.payload
      }
    default:
      return state
  }
}

export const changeSingleTicketStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case 'CHANGE_SINGLE_TICKET_STATUS':
      return {
        data: action.payload
      }
    case 'CHANGE_SINGLE_TICKET_STATUS_FAILED':
      return {
        error: action.payload
      }
    default:
      return state
  }
}

export const getSingleTicketConversationReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_SINGLE_TICKET_CONVERSATION':
      return {
        conversation: action.payload
      }
    case 'GET_SINGLE_TICKET_CONVERSATION_FAILED':
      return {
        error: action.payload
      }
    default:
      return state
  }
}

export const postConversationReducer = (state = {}, action) => {
  switch (action.type) {
    case 'POST_CONVERSATION':
      return {
        data: action.payload
      }
    case 'POST_CONVERSATION_FAILED':
      return {
        error: action.payload
      }
    default:
      return state
  }
}
