/*eslint-disable */
import { useState, useEffect } from 'react'
export const DashboardLogic = () => {
  const [details, setDetails] = useState(null)
  //Get userIp address
  const getUserGeolocationDetails = () => {
    try {
      fetch(
        'https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572'
      )
        .then((response) => response.json())
        .catch((error) => {
          console.log(error)
        })
        .then((data) => setDetails(data))
        .catch((error) => {
          console.log(error)
        })
    } catch (error) {
      setDetails('Error')
    }
  }

  useEffect(() => {
    getUserGeolocationDetails()
  }, [])

  return {
    details
  }
}
