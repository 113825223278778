import { Typography } from '@material-ui/core'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import OpenTicket from '../../components/OpenTicket/OpenTicket'
import Navbar from '../../components/Navbar/index'

const SupportTicket = () => {
  const history = useHistory()
  return (
    <div style={{ overflowX: 'hidden' }}>
      <Navbar />
      <Typography align="center" variant="h4" style={{ paddingTop: '1rem' }}>
        Open Support Cases
      </Typography>
      <div
        style={{
          position: 'relative',
          left: '61%',
          padding: '1rem'
        }}
      >
        <Link
          to="/support-alltickets"
          style={{
            fontSize: '16px',
            marginRight: '1rem',
            textDecoration: 'none'
          }}
        >
          View all Cases
        </Link>
        <button
          style={{
            background: 'rgb(36, 170, 221)',
            padding: '0.5rem',
            color: 'white',
            fontFamily: 'inherit',
            border: '2px solid rgb(36, 170, 221)',
            borderRadius: '0.7rem',
            cursor: 'pointer',
            fontSize: '16px'
          }}
          onClick={(e) => history.push('/create-case')}
        >
          Create Case
        </button>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <div style={{ width: '60rem', paddingBottom: '2rem' }}>
          <OpenTicket />
        </div>
      </div>
    </div>
  )
}

export default SupportTicket
