import axios from 'axios'

const API = process.env.REACT_APP_API

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
}

export const changePasswordHandler = async (id, newPassword) => {
  const body = {
    password: newPassword
  }
  const { data } = await axios.post(`${API}/admin/password/${id}`, body, config)
  return data
}
