/*eslint-disable */
import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { InputAdornment, IconButton } from '@material-ui/core'
import { LinearProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Visibility, VisibilityOff } from '@material-ui/icons'

import { LoginLogic } from './login'

import Logo from '../../../assets/IEMSecure-logo.jpg'

export default function Login() {
  const {
    error,
    loading,
    formik,
    classes,
    setShowPassword,
    showPassword,
    handlePopoverClose,
    handlePopoverOpen,
    open
  } = LoginLogic()

  return (
    <>
      {loading && (
        <LinearProgress
          style={{ marginTop: '4px', marginBottom: '4px' }}
          color="primary"
        />
      )}
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={false} md={3}></Grid>
        <Grid item xs={12} sm={12} md={6} component={Paper} elevation={0}>
          <div className={classes.paper}>
            <Typography align="center">
              <img src={Logo} alt="IEMSecure Logo" className={classes.logo} />
            </Typography>
            <br />
            <Typography component="h1" variant="h6">
              Login
            </Typography>
            {error && (
              <Alert
                style={{ marginTop: '8px', width: '100%' }}
                variant="outlined"
                severity="error"
              >
                {error}
              </Alert>
            )}
            <form
              className={classes.form}
              noValidate
              onSubmit={(e) => {
                e.preventDefault()
                formik.handleSubmit()
              }}
              autoComplete="off"
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                      {/*Info icon for popover helper text*/}
                      <Typography
                        aria-owns={open ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                      ></Typography>
                    </InputAdornment>
                  )
                }}
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Login
              </Button>
              <Link to="forget-password">
                <Typography>Forget Password ?</Typography>
              </Link>
            </form>
          </div>
        </Grid>
        <Grid item xs={false} sm={false} md={3}></Grid>
      </Grid>
    </>
  )
}
