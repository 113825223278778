import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useStyles } from '../auth.style'

import * as yup from 'yup'
import { useFormik } from 'formik'
// Redux
import { useDispatch, useSelector } from 'react-redux'
import { userLogin } from '../../../redux/actions'

export const LoginLogic = () => {
  const history = useHistory()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false)

  const validationSchema = yup.object({
    email: yup.string().email('Invalid email format').required('Required'),
    password: yup.string().required('Password is Required')
  })

  const { data, error, loading } = useSelector((state) => state.userLogin)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (dispatch(userLogin(values.email, values.password))) {
        if (data && !loading) {
          history.push('/twoFactorAuth')
        }
      }
    }
  })

  useEffect(() => {
    if (data) {
      history.push('/twoFactorAuth')
    }
  }, [history, data])

  // For Popover
  const [anchorEl, setAnchorEl] = useState(null)
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  // End of pop over

  return {
    data,
    error,
    loading,
    formik,
    classes,
    setShowPassword,
    showPassword,
    handlePopoverClose,
    handlePopoverOpen,
    open
  }
}
