import axios from 'axios'

const url = `${process.env.REACT_APP_API}/admin`

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
}

export const loginUser = (email, password) =>
  axios.post(`${url}/login`, { email, password }, config)

export const registerUser = (name, email, password) =>
  axios.post(`${url}/register`, {email, password }, config)
