import axios from 'axios'
import { ConfigFunction } from '../../utils/config'
const API = process.env.REACT_APP_API
let userDetails = {}

export const getAdminTickets = () => async (dispatch, getState) => {
  try {
    const config = ConfigFunction(getState)
    const { data } = await axios.get(`${API}/ticket/admin`, config)
    dispatch({
      type: 'GET_ADMIN_TICKETS',
      payload: data
    })
  } catch (error) {
    dispatch({
      type: 'GET_ADMIN_TICKETS_FAILED',
      payload: error
    })
  }
}

export const userIdFrommailId = (email) => async (dispatch, getState) => {
  try {
    const config = ConfigFunction(getState)
    const { data } = await axios.get(
      `${API}/admin/users?email=${email}`,
      config
    )
    userDetails = data
  } catch (error) {
    console.log(error.message)
  }
}

export const createAdminTicket =
  (postData, userInfo) => async (dispatch, getState) => {
    const config = ConfigFunction(getState)
    const userId = userDetails[0]._id
    const subject = postData.subject
    const category = postData.category
    try {
      const { data } = await axios.post(
        `${API}/ticket/admin`,
        { userId, subject, category },
        config
      )
      dispatch({
        type: 'ADMIN_CREATE_TICKET',
        payload: data
      })
    } catch (error) {
      dispatch({
        type: 'ADMIN_CREATE_TICKET_FAILED',
        payload: error
      })
    }
  }

export const getSingleTicketDetals =
  (ticketId) => async (dispatch, getState) => {
    try {
      const config = ConfigFunction(getState)
      const { data } = await axios.get(`${API}/ticket?ticketId=${ticketId}`,config)
      dispatch({
        type: 'GET_SINGLE_TICKET_DETAILS',
        payload: data
      })
    } catch (error) {
      dispatch({
        type: 'GET_SINGLE_TICKET_DETAILS_FAILED',
        payload: error
      })
    }
  }

export const changeSingleTicketStatus =
  (ticketId, setCounter, counter) => async (dispatch, getState) => {
    try {
      const config = ConfigFunction(getState)
      const { data } = await axios.put(
        `${API}/ticket/changeStatus`,
        { ticketId },
        config
      )
      setCounter(counter + 1)
      dispatch({
        type: 'CHANGE_SINGLE_TICKET_STATUS',
        payload: data
      })
    } catch (error) {
      dispatch({
        type: 'CHANGE_SINGLE_TICKET_STATUS_FAILED',
        payload: error
      })
    }
  }

export const getSingleTicketConversation =
  (ticketId) => async (dispatch, getState) => {
    try {
      const config = ConfigFunction(getState)
      const { data } = await axios.get(
        `${API}/ticket/conversation/${ticketId}`,
        config
      )
      dispatch({
        type: 'GET_SINGLE_TICKET_CONVERSATION',
        payload: data
      })
    } catch (error) {
      dispatch({
        type: 'GET_SINGLE_TICKET_CONVERSATION_FAILED',
        payload: error
      })
    }
  }

export const postConversation =
  (body, name, ticketId, setCounter, counter) => async (dispatch, getState) => {
    try {
      const config = ConfigFunction(getState)
      const { data } = await axios.post(
        `${API}/ticket/conversation/${ticketId}`,
        { body, name },
        config
      )
      setCounter(counter + 1)
      dispatch({
        type: 'POST_CONVERSATION',
        payload: data
      })
    } catch (error) {
      dispatch({
        type: 'POST_CONVERSATION_FAILED',
        payload: error
      })
    }
  }
