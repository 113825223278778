import {
  FETCH_USER,
  FETCH_USER_REQUEST,
  FETCH_USER_FAILED,
  UPDATE_USER,
  UPDATE_USER_FAILED
} from '../constants'

const initialState = {
  error: null,
  data: [],
  loading: false
}

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        loading: true
      }
    case FETCH_USER:
      return {
        loading: false,
        data: action.payload
      }

    case FETCH_USER_FAILED:
      return {
        error: action.payload
      }

    default:
      return state
  }
}

export const userCreditReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER:
      return {
        loading: false,
        data: action.payload
      }

    case UPDATE_USER_FAILED:
      return {
        error: action.payload
      }

    default:
      return state
  }
}
