export const ConfigFunction = (getState) => {
  const {
    userLogin: { data }
  } = getState()

  const config = {
    'Content-Type': 'application/json',
    headers: {
      Authorization: `Bearer ${data.token}`
    }
  }

  return config
}
