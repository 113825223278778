// const initialState = {
//     data:{}
// }
export const getNotificationCountReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_NOTIFICATION_COUNT':
      return {
        loading: false,
        data: action.payload,
        isAuthenticated: true,
        error: null
      }
    case 'GET_NOTIFICATION_COUNT_FAIELD':
      return {
        isAuthenticated: false,
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export const getActivityLogsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_ACTIVITY_LOGS':
      return {
        loading: false,
        data: action.payload,
        isAuthenticated: true,
        error: null
      }
    case 'GET_ACTIVITY_LOGST_FAIELD':
      return {
        isAuthenticated: false,
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}
