import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  itemBox: {
    margin: '0.45rem 1rem',
    fontSize: '1rem'
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0.8rem'
  }
}))
