import axios from 'axios'
import { helperConfigFunction } from '../../../utils/helperConfig'

const API = process.env.REACT_APP_API

export const twoFactorAuthHandler = async (email, authCode, userInfo) => {
  const body = {
    email: email,
    authCode: authCode
  }
  const config = helperConfigFunction(userInfo)

  const { data } = await axios.post(`${API}/admin/user/twoFA`, body, config)
  if (data.message !== 'Invalid OTP') {
    localStorage.setItem('userData', JSON.stringify(data))
  }
  console.log(data.message === 'Invalid OTP')
  return data
}
