import { useState } from 'react'
import { useParams, useHistory } from 'react-router'
import swal from 'sweetalert'
import { changePasswordHandler } from './helper'

export const ResetPasswordLogic = () => {
  const history = useHistory()
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [loading, setloading] = useState(false)

  const { id } = useParams()
  console.log(id)
  const handelSubmit = async (e) => {
    e.preventDefault()
    setloading(true)
    const data = await changePasswordHandler(id, newPassword)
    swal(data.message).then((value) => {
      if (value) {
        history.push('/login')
      }
    })
    setloading(false)
    setNewPassword('')
  }

  return {
    password,
    setPassword,
    newPassword,
    setNewPassword,
    showPassword,
    setShowPassword,
    showNewPassword,
    setShowNewPassword,
    handelSubmit,
    loading
  }
}
