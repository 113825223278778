import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.secondary.main,
    margin: theme.spacing(3),
    padding: theme.spacing(2),
    width: '100%'
  },

  infoItems: {
    display: 'flex',
    margin: theme.spacing(2)
  },
  green: {
    color: 'green'
  },
  heart: {
    color: '#fb4262',
    fontSize: '6rem'
  }
}))
