import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminTickets } from '../../redux/actions/TicketRaisingAction'
import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

export const TicketsViewLogic = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAdminTickets())
  }, [dispatch])
  const { tickets } = useSelector((state) => state.getAdminTickets)

  const theme = useTheme()
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  // Sort
  const [sortType, setSortType] = useState('timeDesc')
  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseSort = () => {
    setAnchorEl(null)
  }

  let adminOpenedTickets = []
  tickets &&
    tickets.body.map(
      (ticket) => ticket.isAdmin && adminOpenedTickets.push(ticket)
    )

  let userOpenedTickets = []
  tickets &&
    tickets.body.map(
      (ticket) => !ticket.isAdmin && userOpenedTickets.push(ticket)
    )

  function sorting(typeOfTicket) {
    const sortedTickets =
      tickets &&
      typeOfTicket.sort((a, b) => {
        const isReversed =
          sortType === 'priorityAsc' || sortType === 'timeAsc' ? 1 : -1

        return sortType === 'priorityAsc'
          ? a.priority - b.priority
          : sortType === 'priorityDesc'
          ? b.priority - a.priority
          : isReversed * a.createdAt.localeCompare(b.createdAt)
      })
    return sortedTickets
  }
  let userTickets = sorting(tickets && userOpenedTickets)
  let adminTickets = sorting(tickets && adminOpenedTickets)
  console.log(userOpenedTickets, adminTickets)
  function handleSorting(sortSelected) {
    setSortType(sortSelected)
    setAnchorEl(null)
  }

  return {
    tickets,
    theme,
    value,
    handleChange,
    handleChangeIndex,
    handleMenu,
    anchorEl,
    handleCloseSort,
    handleSorting,
    userTickets,
    adminTickets
  }
}

export function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

export function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}
