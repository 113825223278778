import React from 'react'
import { Button, Container } from '@material-ui/core'
import { useStyles } from './AdminActivityLogs.style'

import Notifications from '../../components/AdminActivityLogs'
import { AdminActivityLogsLogic } from './AdminActivityLogs'

import { CSVLink } from 'react-csv'

export default function AdminActivityLogs() {
  const classes = useStyles()
  const { data } = AdminActivityLogsLogic()
  function elementsInNotification(data) {
    return (
      <Notifications
        key={data._id}
        action={data.action}
        date={data.createdAt}
      />
    )
  }
  return (
    <Container>
      <main className={classes.main}>
        <h1 style={{ marginTop: '3rem', marginBottom: '2rem' }}>
          Notifications
        </h1>
        <Button variant="outlined">
          {' '}
          {data ? (
            <CSVLink
              data={data}
              style={{
                textDecoration: 'none',
                color: '#5b5b65'
              }}
            >
              Export Data
            </CSVLink>
          ) : (
            ''
          )}
        </Button>
        <hr />
        {data && data.map(elementsInNotification)}
      </main>
    </Container>
  )
}
