import {
  Button,
  TextField,
  Paper,
  Container,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  LinearProgress
} from '@material-ui/core'

import { Visibility, VisibilityOff } from '@material-ui/icons'
import { useStyles } from '../auth.style'

import { ResetPasswordLogic } from './ResetPassword'

function ResetPassword() {
  const classes = useStyles()
  const {
    newPassword,
    setNewPassword,
    showNewPassword,
    setShowNewPassword,
    handelSubmit,
    loading
  } = ResetPasswordLogic()
  return (
    <>
      {loading && (
        <LinearProgress
          style={{ marginTop: '4px', marginBottom: '4px' }}
          color="primary"
        />
      )}
      <Container>
        <Grid container component="main" className={classes.root}>
          <Grid item xs={false} sm={false} md={3}>
            {' '}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            component={Paper}
            className={classes.paper}
          >
            <Typography component="h1" variant="h4">
              Change Password
            </Typography>
            <br />
            <Typography component="h1" variant="h5"></Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="New Password"
                type={showNewPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setNewPassword(!showNewPassword)}
                        onMouseDown={() => setShowNewPassword(!showNewPassword)}
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handelSubmit}
              >
                Change Password
              </Button>
            </form>
          </Grid>
          <Grid item xs={false} sm={false} md={3} />
        </Grid>
      </Container>
    </>
  )
}

export default ResetPassword
