import { useState } from 'react'
import { useSelector } from 'react-redux'
import { twoFactorAuthHandler } from './helper'
import swal from 'sweetalert'

const TwoFactorAuth = (history) => {
  const { data } = useSelector((state) => state.userLogin)

  const [authCode, setAuthCode] = useState('')

  const submitHandler = async (e) => {
    e.preventDefault()

    const userInfo = await twoFactorAuthHandler(data.email, authCode, data)

    if (userInfo.message === 'Invalid OTP') {
      swal('Oops', 'Invalid OTP', 'error')
    } else {
      swal('Successfully', 'Logged In', 'success')
      history.push('/')
    }
  }
  return { submitHandler, authCode, setAuthCode }
}

export default TwoFactorAuth
