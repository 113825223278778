import axios from 'axios'
import { ConfigFunction } from '../../utils/config'

const url = `${process.env.REACT_APP_API}/admin`


export const getUsers = async (getState) => {
  let config = ConfigFunction(getState)
  return axios.get(`${url}/users`, config)
}

export const updateCredits = async (id, credits, creditType, getState) => {
  const admin = JSON.parse(localStorage.getItem('userData'))
  const adminName = admin.name
  let config = ConfigFunction(getState)
  await axios.put(
    `${url}/user/${id}/update`,
    { credits, adminName, creditType },
    config
  )
}
