import React, { useEffect } from 'react'
import { useStyles } from './DeveloperUsers.style'
import {getDevUsers} from "./helper"
import moment from 'moment'
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
} from '@material-ui/core'
import { useState } from 'react'


export default function DeveloperUsers() {
  const [data, setData] = useState([])

  useEffect(() => {
    async function fetchData() {
      const {data} = await getDevUsers();
      console.log(data);
      setData(data)
    }
    fetchData();
}, [])


    console.log(data);
  const classes = useStyles()



  return (
    <>
        <Paper elevation={0} className={classes.spacing}>
        <TableContainer style={{ borderRadius: '20px', overflow: 'visible' }}>
          <Table className={classes.table} size="large" padding="normal">
            <TableHead className={classes.shadow}>
              <TableRow>
                {/* <TableCell>
                  <Typography variant="h6" className={classes.headingFont}>
                    Select
                  </Typography>
                </TableCell> */}
                <TableCell>
                  <Typography variant="h6" className={classes.headingFont}>
                    Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" className={classes.headingFont}>
                    Email
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" className={classes.headingFont}>
                    Registered Date
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" className={classes.headingFont}>
                  API Credits
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableRow className={classes.invisible}>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell> </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>

            <TableBody className={classes.shadow}>
              {/*Map data from here*/}
              {data &&
                data.map((user, key) => (
                  <TableRow hover elevation={6}>
                    <TableCell component="th" scope="row">
                      <Typography variant="subtitle2">{user.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">{user.email}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {user.createdAt
                          ? moment(user.createdAt).format('DD MMMM, YYYY')
                          : ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">{user.apiCredits}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}
