import { combineReducers } from 'redux'
import { userRegisterReducer, userLoginReducer } from './authReducer.js'
import { userReducer, userCreditReducer } from './userReducer'
import {
  getNotificationCountReducer,
  getActivityLogsReducer
} from './adminActivityLogsReducer'
import {
  createAdminTicketReducer,
  getAdminTicketsReducer,
  getSingleTicketDetalsReducer,
  changeSingleTicketStatusReducer,
  getSingleTicketConversationReducer,
  postConversationReducer
} from './TicketRaisingReducer.js'

const rootReducer = combineReducers({
  userRegister: userRegisterReducer,
  userLogin: userLoginReducer,
  userList: userReducer,
  credit: userCreditReducer,
  getNotificationCount: getNotificationCountReducer,
  getActivityLogs: getActivityLogsReducer,
  getAdminTickets: getAdminTicketsReducer,
  createAdminTicket: createAdminTicketReducer,
  getSingleTicketDetals: getSingleTicketDetalsReducer,
  changeSingleTicketStatus: changeSingleTicketStatusReducer,
  getSingleTicketConversation: getSingleTicketConversationReducer,
  postConversation: postConversationReducer
})

export default rootReducer
