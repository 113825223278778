import React from 'react'
import Paper from '@material-ui/core/Paper'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import { useHistory } from 'react-router-dom'

export default function TableTickets(tickets) {
  const history = useHistory()

  const columns = [
    { id: 'caseid', label: 'Case ID', minWidth: 100 },
    { id: 'name', label: 'Name', minWidth: 150 },
    { id: 'subject', label: 'Subject', minWidth: 150 },
    {
      id: 'category',
      label: 'Category',
      minWidth: 100,
      align: 'left'
    },
    {
      id: 'priority',
      label: 'Priority',
      minWidth: 120,
      align: 'center'
    },
    {
      id: 'createdat',
      label: 'Created At',
      minWidth: 120,
      align: 'center'
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 120,
      align: 'center'
    }
  ]

  let rows = []

  function createData(
    subject,
    caseid,
    name,
    category,
    priority,
    createdat,
    status,
    ticketId
  ) {
    return {
      subject,
      caseid,
      name,
      category,
      priority,
      createdat,
      status,
      ticketId
    }
  }

  function tableContent(list) {
    let priority = 'General'
    let status = 'Open'
    for (let i = 0; i < list.length; i++) {
      if (!list[i].status) status = 'Resolved'
      if (list[i].priority === 1) priority = 'Most priority'
      else if (list[i].priority === 2) priority = 'Medium priority'
      else if (list[i].priority === 2) priority = 'Less priority'
      else if (list[i].priority === 2) priority = 'Least priority'
      else priority = 'General'
      rows.push(
        createData(
          list[i].subject.substring(0, 16),
          list[i].caseId,
          list[i].user.name,
          list[i].category,
          priority,
          list[i].createdAt.toString().substring(0, 10),
          status,
          list[i]._id
        )
      )
    }
  }

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  console.log(tickets)
  return (
    <Paper sx={{ width: '100%', padding: '0.8rem', borderRadius: '0.8rem' }}>
      {tickets.tickets && tableContent(tickets.tickets)}
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    fontWeight: '700',
                    fontSize: '17px'
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.caseid}
                  >
                    {columns.map((column) => {
                      return (
                        <>
                          {column.id === 'subject' ? (
                            <TableCell
                              style={{
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                history.push(
                                  `/support/?ticketId=${row.ticketId}`
                                )
                              }}
                              key={column.id}
                              align={column.align}
                            >
                              {row[column.id]}
                            </TableCell>
                          ) : (
                            <TableCell
                              style={{
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                history.push(
                                  `/support/?ticketId=${row.ticketId}`
                                )
                              }}
                              key={column.id}
                              align={column.align}
                            >
                              {row[column.id]}
                            </TableCell>
                          )}
                        </>
                      )
                    })}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
