import { useState } from 'react'
import { useHistory } from 'react-router'

import { useDispatch } from 'react-redux'
import { updateUserCredits } from '../../redux/actions'

export const CreditLogic = () => {
  const [openCreditsCard, setOpenCreditsCard] = useState(false)
  const [creditType, setCreditType] = useState('')
  const [open, setOpen] = useState(false)
  const [credit, setCredit] = useState('')

  const dispatch = useDispatch()
  const history = useHistory()

  const handleOpen = (e) => {
    setCreditType(e.target.textContent)
    handleCreditsCardClose()
    setOpen(true)
  }
  // console.log(creditType);
  const handleClose = () => {
    setOpen(false)
  }
  const handleCreditsCardClose = () => {
    setOpenCreditsCard(false)
  }
  const handleCreditsCardOpen = () => {
    setOpenCreditsCard(true)
    console.log('hello souvik', openCreditsCard)
  }

  // update credit of particular user
  const handleSubmit = async (userId) => {
    dispatch(updateUserCredits(userId, credit, creditType))
    setTimeout(function () {
      history.go(0)
    }, 1500)
  }

  return {
    open,
    setOpen,
    credit,
    setCredit,
    handleOpen,
    handleClose,
    handleSubmit,
    handleCreditsCardClose,
    handleCreditsCardOpen,
    openCreditsCard,
    creditType
  }
}
