import React from 'react'
import { useStyles } from './CaseDiscussion.style'
import { CaseDiscussionLogic } from './CaseDiscussion'
import {
  Container,
  Button,
  Typography,
  Paper,
  TextareaAutosize
} from '@material-ui/core'

export default function CaseDiscussion() {
  const classes = useStyles()
  const {
    textBoxHandler,
    inputArea,
    singleTicket,
    statusHandler,
    conversation,
    textBoxConent,
    setTextBoxConent,
    postConversationHandlere
  } = CaseDiscussionLogic()

  return (
    <>
      <Container className={classes.root}>
        <div className={classes.CaseHeader}>
          <Typography variant="h4" component="h2">
            Case ID : {singleTicket && singleTicket.caseId}
          </Typography>
          {singleTicket && singleTicket.status ? (
            <Button onClick={statusHandler} variant="contained" color="primary">
              Resolve
            </Button>
          ) : (
            <Button onClick={statusHandler} variant="contained" color="default">
              Reopen
            </Button>
          )}
        </div>
        <div className={classes.CaseDetails}>
          <Paper
            Paper
            variant="outlined"
            square
            className={classes.CaseDetailsHeading}
          >
            <Typography variant="h6" component="h2" style={{ margin: '1rem' }}>
              Case details
            </Typography>
          </Paper>
          <div className={classes.CaseDetailsBox}>
            <div className={classes.LeftSide}>
              <Typography
                variant="h5"
                component="h2"
                style={{
                  marginLeft: '1rem',
                  marginTop: '2rem'
                }}
              >
                Subject
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                style={{
                  margin: '0rem 0rem 2rem 1rem',
                  color: '#000'
                }}
              >
                {singleTicket && singleTicket.subject}
              </Typography>

              <Typography
                variant="h5"
                component="h2"
                style={{ marginLeft: '1rem' }}
              >
                Case ID
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                style={{
                  margin: '0rem 0rem 2rem 1rem',
                  color: '#000'
                }}
              >
                {singleTicket && singleTicket.caseId}
              </Typography>

              <Typography
                variant="h5"
                component="h2"
                style={{ marginLeft: '1rem' }}
              >
                Created
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                style={{
                  margin: '0rem 0rem 2rem 1rem',
                  color: '#000'
                }}
              >
                {singleTicket &&
                  new Date(singleTicket.updatedAt).toLocaleString()}
              </Typography>

              <Typography
                variant="h5"
                component="h2"
                style={{ marginLeft: '1rem' }}
              >
                Opened by
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                style={{
                  margin: '0rem 0rem 2rem 1rem',
                  color: '#000'
                }}
              >
                {singleTicket && singleTicket.user.name}
              </Typography>
            </div>
            <div className={classes.RightSide}>
              <Typography
                variant="h5"
                component="h2"
                style={{ marginLeft: '1rem' }}
              >
                Status
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                style={{
                  margin: '0rem 0rem 2rem 1rem',
                  color: '#000'
                }}
              >
                {singleTicket && singleTicket.status ? 'Open' : 'Resolved'}
              </Typography>

              <Typography
                variant="h5"
                component="h2"
                style={{ marginLeft: '1rem' }}
              >
                Category
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                style={{
                  margin: '0rem 0rem 2rem 1rem',
                  color: '#000'
                }}
              >
                {singleTicket && singleTicket.category}
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.Discussion}>
          <Paper
            Paper
            variant="outlined"
            square
            className={classes.DiscussionHeading}
          >
            <Typography variant="h6" component="h2" style={{ margin: '1rem' }}>
              Correspondence
            </Typography>
            {singleTicket && singleTicket.status ? (
              <Button
                onClick={textBoxHandler}
                className={inputArea ? classes.active : ''}
                variant="contained"
                color="primary"
              >
                Reply
              </Button>
            ) : (
              ''
            )}
          </Paper>
          <div className={classes.DiscussionBox}>
            {inputArea ? (
              <>
                <div className={classes.HeaderInfo}>
                  <Typography
                    variant="h5"
                    component="h2"
                    style={{ marginLeft: '1rem' }}
                  >
                    Reply
                  </Typography>
                  <p
                    variant="h6"
                    component="h2"
                    style={{
                      margin: '0rem 0rem 2rem 1rem',
                      color: '#000'
                    }}
                  >
                    Do not share any sensitive information in case
                    correspondences, such as credentials, credit cards, signed
                    URLs, or personally identifiable information.
                  </p>
                </div>
                <div className={classes.InputArea}>
                  <TextareaAutosize
                    onChange={(e) => setTextBoxConent(e.target.value)}
                    value={textBoxConent}
                    aria-label="minimum height"
                    minRows={3}
                    placeholder="Enter Your Message"
                    style={{
                      width: '100%',
                      height: '100%',
                      border: '1px solid gray',
                      outline: 'none',
                      resize: 'none',
                      paddingLeft: '1rem'
                    }}
                  />
                </div>
                <div className={classes.Buttons}>
                  <Button
                    onClick={textBoxHandler}
                    variant="contained"
                    style={{ marginRight: '1rem' }}
                  >
                    Cencel
                  </Button>
                  <Button
                    onClick={() => {
                      textBoxHandler()
                      postConversationHandlere()
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </Button>
                </div>
              </>
            ) : (
              ''
            )}

            {conversation &&
              conversation.body.map((currentConversation) => {
                return (
                  <div className={classes.ChatArea}>
                    <div className={classes.UserDetails}>
                      <Typography variant="h6" component="h2">
                        {currentConversation.name}
                      </Typography>
                      <p
                        variant="h6"
                        component="h2"
                        style={{
                          color: '#000',
                          textAlign: 'justify'
                        }}
                      >
                        {new Date(
                          currentConversation.createdAt
                        ).toLocaleString()}
                      </p>
                    </div>
                    <div className={classes.Message}>
                      <Typography variant="h6" component="h2">
                        {currentConversation.body}
                      </Typography>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </Container>
    </>
  )
}
