import React from 'react'
import { useStyles } from './credit.style'
import {
  Button,
  TextField,
  Fade,
  Modal,
  Backdrop,
  Typography,
  ClickAwayListener,
  Card
} from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import { CreditLogic } from './credit'
function Credit({ userId }) {
  const classes = useStyles()
  const {
    open,
    credit,
    setCredit,
    handleOpen,
    handleClose,
    handleSubmit,
    handleCreditsCardClose,
    handleCreditsCardOpen,
    openCreditsCard,
    creditType
  } = CreditLogic()
  return (
    <>
      <div>
        <ClickAwayListener onClickAway={handleCreditsCardClose}>
          <Typography
            variant="h6"
            color="primary"
            align="center"
            style={{ margin: 'auto 1rem', position: 'relative' }}
          >
            <div
              // onClick={handleOpen}
              onClick={handleCreditsCardOpen}
              className={classes.modalbtn}
            >
              <Button
                color="primary"
                variant="contained"
                style={{ color: 'white' }}
              >
                Change Credit
              </Button>
            </div>

            {openCreditsCard ? (
              <Card elevation={4} className={classes.navCreditDiv}>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      {/* <TableCell align="right">Content :&nbsp;{data && data.creditsLeft}</TableCell> */}
                      <TableCell align="center">
                        <Button onClick={handleOpen}>Content</Button>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {/* <TableCell align="center">Source Code :&nbsp;{data && data.sourceCodeCreditsLeft}</TableCell> */}
                      <TableCell align="center">
                        <Button onClick={handleOpen}>Source Code</Button>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {/* <TableCell align="center">Research Paper :&nbsp;{data && data.researchCreditsLeft}</TableCell> */}
                      <TableCell align="center">
                        <Button onClick={handleOpen}>Research Paper</Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>
            ) : (
              ''
            )}
          </Typography>
        </ClickAwayListener>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 600
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography
              variant="h6"
              color="primary"
              id="transition-modal-title"
            >
              Edit Credit for <b>{creditType}</b>
            </Typography>
            <p id="transition-modal-description">
              <TextField
                type="number"
                spellCheck="true"
                required
                placeholder="Credit Amount"
                multiline
                fullWidth
                variant="outlined"
                rows={5}
                rowsMax={5}
                className={classes.margin}
                value={credit}
                onChange={(e) => setCredit(e.target.value)}
              />
              <br />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit(userId)}
                className={classes.btn}
              >
                Submit
              </Button>
              <Button variant="contained" color="default" onClick={handleClose}>
                Cancel
              </Button>
            </p>
          </div>
        </Fade>
      </Modal>
    </>
  )
}

export default Credit
