import { Paper, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useStyles } from './CreateTicket.style'
import { useDispatch, useSelector } from 'react-redux'
import {
  createAdminTicket,
  userIdFrommailId
} from '../../redux/actions/TicketRaisingAction'
import swal from 'sweetalert'

const CreateTicket = () => {
  const [form1, setForm1] = useState(true)
  const dispatch = useDispatch()

  const { data } = useSelector((state) => state.userLogin)

  // eslint-disable-next-line no-unused-vars
  const [form2, setForm2] = useState(false)
  const [display, setDisplay] = useState(true)
  const [bgCard1, setBgCard1] = useState('#e7f1f9')
  const [bgCard2, setBgCard2] = useState()
  const [border1, setBorder1] = useState('#aacdea')
  const [border2, setBorder2] = useState()
  const [postData, setPostData] = useState({
    userId: '',
    subject: '',
    category: 'General'
  })
  const classes = useStyles()
  const handleSubmit = async (e) => {
    e.preventDefault()
    dispatch(userIdFrommailId(postData.userId)).then(() => {
      dispatch(createAdminTicket(postData, data)).then(() =>
        swal(
          'Successfully',
          'Your Case was added successfully',
          'success'
        ).then(
          setPostData({
            userId: '',
            subject: '',
            category: 'General'
          })
        )
      )
    })
  }
  const handleSubmit2 = async (e) => {
    e.preventDefault()
    dispatch(userIdFrommailId(postData.userId)).then(() => {
      dispatch(createAdminTicket(postData)).then(() =>
        swal(
          'Successfully',
          'Your Case was added successfully',
          'success'
        ).then(
          setPostData({
            userId: '',
            subject: '',
            category: 'General'
          })
        )
      )
    })
  }
  const handleClick1 = () => {
    setBgCard2('#fff')
    setBgCard1('#e7f1f9')
    setBorder1('#aacdea')
    setBorder2('#fff')
    setForm2(false)
    setForm1(true)
    setDisplay(true)
    setPostData({
      userId: '',
      subject: '',
      category: 'General'
    })
  }
  const handleClick2 = () => {
    setBgCard1('#fff')
    setBgCard2('#e7f1f9')
    setBorder1('#fff')
    setBorder2('#aacdea')
    setForm1(false)
    setForm2(true)
    setDisplay(true)
    setPostData({
      userId: '',
      subject: '',
      category: 'General'
    })
  }
  return (
    <div style={{ background: '#f5f5f5' }}>
      <Typography variant="h4" align="center" style={{ padding: '1.5rem' }}>
        Create Case
      </Typography>
      <div className={classes.container}>
        <Paper
          className={classes.card}
          style={{
            background: `${bgCard1}`,
            border: `2px solid ${border1}`
          }}
          onClick={handleClick1}
        >
          <div
            style={{
              textAlign: 'center',
              paddingTop: '2.8rem'
            }}
          >
            <Typography variant="h6" style={{ lineHeight: '2.5rem' }}>
              Account/Billing related
            </Typography>
            <Typography variant="h7" style={{ color: 'black' }}>
              Cases related to Account, Payment, Billing..
            </Typography>
          </div>
        </Paper>
        <Paper
          className={classes.card}
          style={{
            background: `${bgCard2}`,
            border: `2px solid ${border2}`
          }}
          onClick={handleClick2}
        >
          <div
            style={{
              textAlign: 'center',
              paddingTop: '2.8rem'
            }}
          >
            <Typography variant="h6" style={{ lineHeight: '2.5rem' }}>
              Technical related
            </Typography>
            <Typography variant="h7" style={{ color: 'black' }}>
              Cases related to Content, Research, Code, CopyRight...
            </Typography>
          </div>
        </Paper>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {display ? (
          <>
            {form1 ? (
              <Paper
                style={{
                  height: '25rem',
                  width: '74%'
                }}
              >
                <Typography
                  variant="h6"
                  align="center"
                  style={{
                    padding: '1rem',
                    color: '#24aadd',
                    marginLeft: '4rem',
                    fontWeight: '600'
                  }}
                >
                  Account/Billing related
                </Typography>
                <form
                  style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                  onSubmit={handleSubmit}
                >
                  <div
                    style={{
                      flexDirection: 'column'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '1rem'
                      }}
                    >
                      <label
                        style={{
                          fontSize: '18px',
                          marginRight: '1.6rem'
                        }}
                      >
                        Email Id
                      </label>
                      <input
                        className={classes.inputHolder}
                        value={postData.userId}
                        onChange={(e) => {
                          setPostData({
                            ...postData,
                            userId: e.target.value
                          })
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '1rem'
                      }}
                    >
                      <label
                        style={{
                          fontSize: '18px',
                          marginRight: '0.9rem'
                        }}
                      >
                        Category
                      </label>

                      <select
                        className={classes.inputHolder}
                        value={postData.category}
                        onChange={(e) => {
                          setPostData({
                            ...postData,
                            category: e.target.value
                          })
                        }}
                      >
                        <option>General</option>
                        <option>Account</option>
                        <option>Payment Issue</option>
                        <option>Billing Question</option>
                      </select>
                      <div
                        style={{
                          pointerEvents: 'none',
                          position: 'relative',
                          top: '0.3rem',
                          right: '2rem'
                        }}
                      >
                        <svg
                          style={{
                            fill: 'currentcolor',
                            height: '25px',
                            width: '25px'
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '1rem'
                      }}
                    >
                      <label
                        style={{
                          fontSize: '18px',
                          marginRight: '1.8rem'
                        }}
                      >
                        Subject
                      </label>
                      <textarea
                        className={classes.inputHolder}
                        rows="6"
                        cols="45"
                        value={postData.subject}
                        onChange={(e) => {
                          setPostData({
                            ...postData,
                            subject: e.target.value
                          })
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginLeft: '4.5rem',
                        padding: '1rem'
                      }}
                    >
                      <button
                        style={{
                          padding: '0.4rem',
                          height: '35px',
                          width: '80px',
                          background: '#24aadd',
                          border: '2px solid #24aadd',
                          fontSize: '16px',
                          color: '#fff',
                          borderRadius: '5px',
                          cursor: 'pointer'
                        }}
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </Paper>
            ) : (
              <Paper
                style={{
                  height: '25rem',
                  width: '74%'
                }}
              >
                <Typography
                  variant="h6"
                  align="center"
                  style={{
                    padding: '1rem',
                    color: '#24aadd',
                    marginLeft: '4rem',
                    fontWeight: '600'
                  }}
                >
                  Technical related
                </Typography>
                <form
                  style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <div
                    style={{
                      flexDirection: 'column'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '1rem'
                      }}
                    >
                      <label
                        style={{
                          fontSize: '18px',
                          marginRight: '1.6rem'
                        }}
                      >
                        Email Id
                      </label>
                      <input
                        className={classes.inputHolder}
                        value={postData.userId}
                        onChange={(e) => {
                          setPostData({
                            ...postData,
                            userId: e.target.value
                          })
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '1rem'
                      }}
                    >
                      <label
                        style={{
                          fontSize: '18px',
                          marginRight: '0.9rem'
                        }}
                      >
                        Category
                      </label>

                      <select
                        className={classes.inputHolder}
                        value={postData.category}
                        onChange={(e) => {
                          setPostData({
                            ...postData,
                            category: e.target.value
                          })
                        }}
                      >
                        <option>General</option>
                        <option>Content Plag</option>
                        <option>Research Plag</option>
                        <option>Code Plag</option>
                        <option>copyright</option>
                      </select>
                      <div
                        style={{
                          pointerEvents: 'none',
                          position: 'relative',
                          top: '0.3rem',
                          right: '2rem'
                        }}
                      >
                        <svg
                          style={{
                            fill: 'currentcolor',
                            height: '25px',
                            width: '25px'
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '1rem'
                      }}
                    >
                      <label
                        style={{
                          fontSize: '18px',
                          marginRight: '1.8rem'
                        }}
                      >
                        Subject
                      </label>
                      <textarea
                        className={classes.inputHolder}
                        rows="6"
                        cols="45"
                        value={postData.subject}
                        onChange={(e) => {
                          setPostData({
                            ...postData,
                            subject: e.target.value
                          })
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginLeft: '4.5rem',
                        padding: '1rem'
                      }}
                    >
                      <button
                        style={{
                          padding: '0.4rem',
                          height: '35px',
                          width: '80px',
                          background: '#24aadd',
                          border: '2px solid #24aadd',
                          fontSize: '16px',
                          color: '#fff',
                          borderRadius: '5px',
                          cursor: 'pointer'
                        }}
                        type="button"
                        onClick={handleSubmit2}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </Paper>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default CreateTicket
