import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  notificationsDetails: {
    width: '100%',
    height: '10vh',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '1.5rem'
  }
}))
