import React from 'react'
import { Link } from 'react-router-dom'

import {
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Button
} from '@material-ui/core'
import NotificationsIcon from '@material-ui/icons/Notifications'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import Tooltip from '@material-ui/core/Tooltip'

import Logo from '../../assets/IEMSecure-logo.jpg'
import MenuIcon from '@material-ui/icons/Menu'

import { NavbarLogic } from './navbar'

function Navbar() {
  const { classes, toggle, setToggle, isMobile, handelLogout, GotoLogs, data } =
    NavbarLogic()

  if (data > 0) {
    document.title = `IEMSecure Admin (${data})`
  } else {
    document.title = 'IEMSecure Admin'
  }

  // console.log("GETTING NOTIFICATION COUNT", data);
  return (
    <>
      <AppBar position="static" color="secondary" elevation={0}>
        <Toolbar>
          {/* Nav Header */}
          <Link
            to="/"
            className={classes.title}
            style={{ textDecoration: 'none', fontWeight: 'bolder' }}
          >
            <Typography variant="h3">
              <img src={Logo} alt="IEMSecure Logo" width={150} />
            </Typography>
          </Link>
          {isMobile ? (
            <>
              <Typography
                variant="body2"
                align="right"
                className={classes.navItems}
              >
                <Link
                  to="/change-password"
                  style={{
                    textDecoration: 'none',
                    fontWeight: 'bolder'
                  }}
                >
                  <Button variant="outlined">Change Password</Button>
                </Link>
              </Typography>
              <Tooltip title="Admin Activities">
                <div
                  onClick={GotoLogs}
                  style={{
                    cursor: 'pointer',
                    position: 'relative'
                  }}
                >
                  {data > 0 ? (
                    <>
                      <NotificationsIcon
                        style={{
                          cursor: 'pointer',
                          position: 'relative'
                        }}
                      />
                      <span className={classes.countNotification}>{data}</span>
                    </>
                  ) : (
                    <NotificationsIcon
                      style={{
                        cursor: 'pointer',
                        position: 'relative'
                      }}
                    />
                  )}
                </div>
              </Tooltip>
              <Tooltip title="Support" style={{ marginLeft: '4rem' }}>
                <Link to="/support-ticket">
                  <HelpOutlineIcon
                    style={{
                      cursor: 'pointer',
                      position: 'relative',
                      color: 'black'
                    }}
                  />
                </Link>
              </Tooltip>
              <Button
                variant="outlined"
                onClick={handelLogout}
                style={{ margin: '0 2rem 0 4rem' }}
              >
                {' '}
                Logout{' '}
              </Button>
              {/* ---------------End of  Desktop View Nav items-------------------------- */}
            </>
          ) : (
            <>
              {/* Mobile view navbar */}
              <IconButton
                edge="start"
                className={classes.menuButton}
                onClick={() => setToggle(true)}
                color="inherit"
                aria-label="menu"
              >
                <MenuIcon />
              </IconButton>

              {/* --------------------------------------FOR MOBILE VIEW--------------------------- */}
              <Drawer
                anchor="right"
                open={toggle}
                onClose={() => setToggle(false)}
              >
                <List className={classes.list}>
                  <ListItem button divider>
                    <Link to="/change-password" className={classes.anchor}>
                      <ListItemText
                        inset
                        primary="Change Password"
                        className={classes.mobilenavItems}
                      />
                    </Link>
                  </ListItem>
                  <ListItem button divider>
                    {data > 0 ? (<ListItemText inset primary={`${data} Notifications`} onClick={GotoLogs}/>): (<ListItemText inset primary="Admin Activities" onClick={GotoLogs}/>)}
                  </ListItem>

                  <ListItem button divider>
                    <Link to="/support-ticket" className={classes.anchor}>
                      <ListItemText
                        inset
                        primary="Support Ticket"
                        className={classes.mobilenavItems}
                      />
                    </Link>
                  </ListItem>


                  <ListItem button divider>
                    <ListItemText
                      inset
                      primary="Logout"
                      className={classes.mobilenavItems}
                      onClick={handelLogout}
                    />
                  </ListItem>
                </List>
              </Drawer>
              {/* ---------------------------------FOR MOBILE VIEW----------------------------------- */}
            </>
          )}
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Navbar
