import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom'
import { NavRoute } from './Layout/NavRoute'

import ScrollToTop from './ScrollTotop'

// Redux stuff
import { useSelector } from 'react-redux'

import Login from './pages/Auth/Login'
import Dashboard from './pages/Dashboard'
import TwoFactorAuth from './pages/Auth/TwoFactorAuth'
import ForgetPassword from './pages/Auth/ForgetPassword'
import ChangePassword from './pages/ChangePassword'
import ResetPassword from './pages/Auth/ResetPassword'
import AdminActivityLogs from './pages/AdminActivity'
import TicketsView from './components/TicketsView/TicketsView.jsx'
import SupportTicket from './pages/SupportTicket/SupportTicket'
import CreateTicket from './components/CreateTicket/CreateTicket'
import CaseDiscussion from './pages/CaseDiscussion'

function Routes() {
  const { data } = useSelector((state) => state.userLogin)
  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
            {!data && <Redirect exact from="/" to="/Login" />}
            <Route exact path="/Login" component={Login} />
            <Route exact path="/twoFactorAuth" component={TwoFactorAuth} />

            <Route exact path="/forget-password" component={ForgetPassword} />
            <Route exact path="/reset-password/:id" component={ResetPassword} />
            <Route exact path="/admin-activity" component={AdminActivityLogs} />
            <Route exact path="/support-alltickets" component={TicketsView} />
            <Route exact path="/support-ticket" component={SupportTicket} />
            <Route exact path="/create-case" component={CreateTicket} />
            <NavRoute
              exact
              path="/change-password"
              component={ChangePassword}
            />
            <NavRoute exact path="/support" component={CaseDiscussion} />
            <NavRoute exact path="/" component={Dashboard} />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    </>
  )
}

export default Routes
