import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    // background: 'red',
    width: '100%',
    height: 'auto',
    padding: '2rem',
    paddingBottom: '5rem'
  },
  CaseHeader: {
    width: '100%',
    height: '4rem',
    // background: 'blue',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-Between'
  },
  CaseDetails: {
    marginBottom: '2rem',
    width: '100%',
    height: 'auto',
    paddingBottom: '2rem',
    background: '#fff',
    boxShadow:
      '0 1px 1px 0 rgb(0 28 36 / 30%), 1px 1px 1px 0 rgb(0 28 36 / 15%), -1px 1px 1px 0 rgb(0 28 36 / 15%)'
  },
  CaseDetailsHeading: {
    width: '100%',
    height: '4rem',
    padding: '1rm 1rem',
    // verticalAlign:'center',
    background: '#fafafa',
    borderBottom: '1px solid gray'
  },
  CaseDetailsBox: {
    width: '100%',
    height: '25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-Between',
    padding: '1rem'
  },
  LeftSide: {
    width: '50%',
    height: '100%',
    borderRight: '0.1rem solid gray'
  },
  RightSide: {
    width: '50%',
    height: '100%'
  },

  Discussion: {
    marginBottom: '2rem',
    width: '100%',
    height: 'auto',
    paddingBottom: '2rem',
    background: '#fff',
    boxShadow:
      '0 1px 1px 0 rgb(0 28 36 / 30%), 1px 1px 1px 0 rgb(0 28 36 / 15%), -1px 1px 1px 0 rgb(0 28 36 / 15%)'
  },
  DiscussionHeading: {
    width: '100%',
    height: '4rem',
    padding: '1rem 1rem',
    // verticalAlign:'center',
    background: '#fafafa',
    borderBottom: '1px solid gray',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-Between'
  },
  DiscussionBox: {
    width: '100%',
    height: 'auto',
    padding: '1rem 0rem'
  },
  HeaderInfo: {
    width: '100%',
    height: '2rem',
    marginBottom: '2rem'
  },
  InputArea: {
    width: '95%',
    height: '10rem',
    margin: 'auto'
  },
  Buttons: {
    width: '100%',
    height: '3rem',
    textAlign: 'right',
    marginTop: '2rem',
    paddingRight: '2rem'
  },
  ChatArea: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-Between',
    borderTop: '1px solid gray',
    borderBottom: '1px solid gray'
  },
  UserDetails: {
    width: '15%',
    height: '100%',
    borderRight: '1px solid gray',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2rem 1rem'
  },
  Message: {
    width: '85%',
    height: '100%',
    padding: '2rem 1rem'
  },
  active: {
    display: 'none'
  }
}))
