/*eslint-disable */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { fetchUsers } from '../../redux/actions'
import swal from 'sweetalert'
import {
  deleteUserHelper,
  deleteManyUserHelper,
  allPurchaseHistoryHelper
} from './helper'
import moment from 'moment'

export const UserLogic = () => {
  const [devUsers, setDevusers] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const [deleteUsers, setDeleteUsers] = useState({
    userIds: [],
    userPass: [],
    userName: []
  })
  const [purchaseHistory, setPurchaseHistory] = useState([])
  const { data: userData } = useSelector((state) => state.userLogin)
  const { data, loading } = useSelector((state) => state.userList)
  const numberOfUSers = data && data.length
  console.log(data)

  const userToggle = () => setDevusers(!devUsers)

  const handleTick = (user, event) => {
    // console.log(user);
    // console.log(user, event.target.checked);

    if (event.target.checked) {
      setDeleteUsers((prevVal) => {
        return {
          userIds: [...prevVal.userIds, user._id],
          userPass: [...prevVal.userPass, user.password],
          userName: [...prevVal.userName, user.name]
        }
      })
    } else {
      var deleteUserIdIndex = deleteUsers.userIds.indexOf(user._id) //get id index
      deleteUsers.userIds.splice(deleteUserIdIndex, 1)

      var deleteUserPassIndex = deleteUsers.userPass.indexOf(user.password) //get password index
      deleteUsers.userPass.splice(deleteUserPassIndex, 1)
    }
  }

  // CSV Export
  var csvData = [
    [
      'Id',
      'Name',
      'Email',
      'Password',
      'Country Code',
      'Phone',
      'Current Plan',
      'Credits Left',
      'Registered Date'
    ]
  ]

  data &&
    data.map((user) => {
      csvData.push([
        user._id,
        user.name,
        user.email,
        user.password,
        user.country_code,
        user.phn_no,
        user.current_plan,
        user.creditsLeft,
        user.createdAt ? moment(user.createdAt).format('DD MMMM, YYYY') : ''
      ])
    })

  // get all payment details
  useEffect(async () => {
    const paymentDetails = await allPurchaseHistoryHelper()
    setPurchaseHistory(paymentDetails)
  }, [])
  // Purchase History CSV Export
  const csvPurchaseData = [
    ['User Name', 'Credits', 'Amount', 'Date & Time', 'Recipt URL']
  ]
  purchaseHistory &&
    purchaseHistory.map((paymentDetail) => {
      csvPurchaseData.push([
        paymentDetail && paymentDetail.user && paymentDetail.user.name,
        paymentDetail && paymentDetail.plan && paymentDetail.plan.credit,
        paymentDetail && paymentDetail.plan && paymentDetail.plan.amount,
        paymentDetail.createdAt,
        paymentDetail.paymentResult.receipt_url
      ])
    })
  // multiple user delete
  const multipleDeleteUsers = async () => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete these users?',
      icon: 'warning',
      buttons: true,
      dangerMode: true
    }).then(async (willDelete) => {
      if (willDelete) {
        // delete multiple user
        console.log(deleteUsers)
        await deleteManyUserHelper(
          deleteUsers.userIds,
          deleteUsers.userPass,
          deleteUsers.userName
        )

        swal('Deleted!', 'All Users has been deleted!', 'success')

        setTimeout(() => {
          history.go(0)
        }, 1500)
      } else {
        swal('Your user is not deleted!')
      }
    })
  }

  useEffect(() => {
    dispatch(fetchUsers())
  }, [dispatch])

  // single user delete
  const userDelete = (id, uid) => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete this user?',
      icon: 'warning',
      buttons: true,
      dangerMode: true
    }).then(async (willDelete) => {
      if (willDelete) {
        // delete single user
        await deleteUserHelper(id, uid, userData.token)

        swal('Deleted!', 'User has been deleted!', 'success')

        setTimeout(function () {
          history.go()
        }, 1500)
      } else {
        swal('Your user is not deleted!')
      }
    })
  }
  return {
    numberOfUSers,
    data,
    loading,
    userDelete,
    handleTick,
    csvData,
    deleteUsers,
    multipleDeleteUsers,
    csvPurchaseData,
    purchaseHistory,
    devUsers,
    userToggle
  }
}
