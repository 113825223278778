import { useState } from 'react'
import swal from 'sweetalert'
import { changePasswordHandler } from './helper'

export const ChangePasswordLogic = () => {
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [loading, setloading] = useState(false)

  const handelSubmit = async () => {
    setloading(true)
    const data = await changePasswordHandler(password, newPassword)
    swal(data.message)
  }

  return {
    password,
    setPassword,
    newPassword,
    setNewPassword,
    showPassword,
    setShowPassword,
    showNewPassword,
    setShowNewPassword,
    handelSubmit,
    loading
  }
}
