import {
  FETCH_USER,
  FETCH_USER_REQUEST,
  FETCH_USER_FAILED,
  UPDATE_USER,
  UPDATE_USER_FAILED
} from '../constants'

import { getUsers, updateCredits } from '../api'

export const fetchUsers = () => async (dispatch, getState) => {
  dispatch({
    type: FETCH_USER_REQUEST
  })
  try {
    const { data } = await getUsers(getState)
    dispatch({
      type: FETCH_USER,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: FETCH_USER_FAILED,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    })
  }
}

export const updateUserCredits =
  (id, credit, creditType) => async (dispatch, getState) => {
    try {
      const { data } = await updateCredits(id, credit, creditType, getState)
      dispatch({
        type: UPDATE_USER,
        payload: data
      })
    } catch (error) {
      dispatch({
        type: UPDATE_USER_FAILED,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      })
    }
  }
