import { makeStyles } from '@material-ui/core/styles'

const sideNavWidth = 210

export const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1
    // margin: "1rem 1rem 1rem 0",
    // color: theme.palette.primary.secondary,
  },
  navItems: {
    color: theme.palette.primary.secondary,
    flexGrow: 1,
    fontWeight: 600,
    fontSize: '1.22rem',
    margin: '0 4.2rem 0 1.2rem'
  },
  menuButton: {
    marginRight: theme.spacing(3),
    color: theme.palette.primary.main
  },
  list: {
    width: sideNavWidth
  },

  mobilenavItems: {
    color: '#4D7178',
    fontWeight: 600,
    margin: theme.spacing(3, 0)
  },
  countNotification: {
    position: 'absolute',
    left: '1rem',
    top: '-0.5rem',
    background: 'red',
    width: '1.2rem',
    height: '1.2rem',
    textAlign: 'center',
    borderRadius: '0.2rem',
    color: 'white'
  },
  anchor:{
    textDecoration: 'none',
  }
}))
