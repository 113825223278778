import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#38a1c1',
      secondary: '#4D7178'
    },
    secondary: {
      main: '#fffafa'
    }
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    h1: {
      fontFamily: "'Montserrat', sans-serif"
    },
    h2: {
      fontFamily: "'Montserrat', sans-serif"
    },
    h3: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 500
    },
    h4: {
      fontFamily: "'Montserrat', sans-serif"
    },
    h5: {
      fontFamily: "'Montserrat', sans-serif"
    },
    h6: {
      fontFamily: "'Montserrat', sans-serif"
    },
    h7: {
      fontFamily: "'Montserrat', sans-serif"
    },
    body1: {
      color: '#5b5b65'
    },
    body2: {
      color: '#5b5b65'
    },
    subtitle1: {
      fontFamily: "Montserrat', sans-serif"
    }
  },
  props: {
    MuiTooltip: {
      arrow: true
    }
  },
  shape: {
    borderRadius: 10
  },
  overrides: {
    MuiStepper: {
      root: {
        background: 'none',
        border: 'none'
      }
    }
  }
})

export default responsiveFontSizes(theme)
