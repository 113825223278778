import moment from 'moment'
import {
  Checkbox,
  Container,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Button
} from '@material-ui/core'
import { CSVLink } from 'react-csv'

import { useStyles } from './user.style.js'
import { UserLogic } from './user'
import Credit from '../Credit/index.jsx'
import { Link } from 'react-router-dom'
import DeveloperUsers from '../DeveloperUsers/index.jsx'

function Users() {
  const classes = useStyles()
  const {
    data,
    userDelete,
    csvData,
    handleTick,
    multipleDeleteUsers,
    csvPurchaseData,
    devUsers,
    userToggle
  } = UserLogic()




  return (
    <div>
      <Container width="lg">
        {!devUsers ?        
        <Button
          variant="contained"
          color="primary"
          style={{ color: '#fff', marginRight:'5rem' }}
          onClick={() => multipleDeleteUsers()}
        >
          Delete Users
        </Button> : ""}
        <Button
          variant="contained"
          color="primary"
          style={{ color: '#fff' }}
          onClick={userToggle}
        >
          Show  {!devUsers? "Developer" : "Regular"} user
        </Button>

        <Link to="/support-ticket">
          <Button
            variant="contained"
            color="primary"
            style={{
              float: 'right',
              color: '#fff'
            }}
          >
            SUPPORT
          </Button>
        </Link>
        <Button
          variant="contained"
          color="primary"
          style={{ float: 'right', marginRight: '1rem' }}
        >
          <CSVLink
            style={{ color: '#fff', textDecoration: 'none' }}
            data={csvData}
          >
            Export Data
          </CSVLink>
        </Button>

        <Button
          variant="contained"
          color="primary"
          style={{ float: 'right', marginRight: '1rem' }}
        >
          <CSVLink
            style={{ color: '#fff', textDecoration: 'none' }}
            data={csvPurchaseData}
          >
            Purchase History
          </CSVLink>
        </Button>
      </Container>
      {devUsers ? <DeveloperUsers /> : (<Paper elevation={0} className={classes.spacing}>
        <TableContainer style={{ borderRadius: '20px', overflow: 'visible' }}>
          <Table className={classes.table} size="large" padding="normal">
            <TableHead className={classes.shadow}>
              <TableRow>
                <TableCell>
                  <Typography variant="h6" className={classes.headingFont}>
                    Select
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" className={classes.headingFont}>
                    Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" className={classes.headingFont}>
                    Email
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" className={classes.headingFont}>
                    Registered Date
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" className={classes.headingFont}>
                    Org Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" className={classes.headingFont}>
                    Contact
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" className={classes.headingFont}>
                    Content
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" className={classes.headingFont}>
                    Source Code
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" className={classes.headingFont}>
                    Research Paper
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" className={classes.headingFont}>
                    Edit Credit
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" className={classes.headingFont}>
                    Delete User
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableRow className={classes.invisible}>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell> </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>

            <TableBody className={classes.shadow}>
              {/*Map data from here*/}
              {data &&
                data.map((user, key) => (
                  <TableRow hover elevation={6}>
                    <TableCell>
                      <Checkbox
                        onClick={(e) => handleTick(user, e)}
                        inputProps={{
                          'aria-label': 'controlled'
                        }}
                        style={{
                          color: '#ff7575',
                          '&.Mui-checked': {
                            color: 'red'
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography variant="subtitle2">{user.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">{user.email}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {user.createdAt
                          ? moment(user.createdAt).format('DD MMMM, YYYY')
                          : ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {user.Org_name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">{user.phn_no}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {user.creditsLeft}{' '}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {user.sourceCodeCreditsLeft}{' '}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {user.researchCreditsLeft}{' '}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Credit userId={user._id} />
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => userDelete(user._id, user.password)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>)}
      
    </div>
  )
}

export default Users
