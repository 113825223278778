import { useEffect, useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { useStyles } from './navbar.style'
import { useMediaQuery } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { userLogout } from '../../redux/actions'
import { useHistory } from 'react-router'
import { getNotificationCount } from '../../redux/actions/adminActivityLogsActions'

export const NavbarLogic = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [toggle, setToggle] = useState(false)
  const history = useHistory()

  useEffect(() => {
    dispatch(getNotificationCount())
  }, [dispatch])

  const { data } = useSelector((state) => state.getNotificationCount)
  useEffect(() => {
    if (data > 0) {
      document.title = `iemSecure Admin (${data})`
    } else {
      document.title = 'iemSecure Admin'
    }
  }, [data])

  // redirect to login  page if logged out
  const handelLogout = () => {
    dispatch(userLogout())
    history.push('/login')
  }

  // redirect to adminActivityLogs
  const GotoLogs = () => {
    history.push('/admin-activity')
  }

  // For media querries
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.up('md'))

  return {
    classes,
    toggle,
    setToggle,
    isMobile,
    handelLogout,
    GotoLogs,
    data
  }
}
