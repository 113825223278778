import axios from 'axios'
import { ConfigFunction } from '../../utils/config'

export const getNotificationCount = () => async (dispatch) => {
  const API = process.env.REACT_APP_API
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const { data } = await axios.get(`${API}/notification/get-count`, config)

    dispatch({
      type: 'GET_NOTIFICATION_COUNT',
      payload: data
    })
  } catch (error) {
    dispatch({
      type: 'GET_NOTIFICATION_COUNT_FAIELD',
      payload: error
    })
  }
}

export const getActivityLogs = () => async (dispatch,getState) => {
  const API = process.env.REACT_APP_API
let config = ConfigFunction(getState)
  try {
    const { data } = await axios.get(`${API}/adminlogs/get-logs`, config)

    dispatch({
      type: 'GET_ACTIVITY_LOGS',
      payload: data
    })
  } catch (error) {
    dispatch({
      type: 'GET_ACTIVITY_LOGS_FAIELD',
      payload: error
    })
  }
}
