import React from 'react'
import { TabPanel, TicketsViewLogic, a11yProps } from './TicketsView'
import TableTickets from './TableTickets'
import Box from '@material-ui/core/Box'
import { Tab } from '@material-ui/core'
import { MenuItem } from '@material-ui/core'
import { Tabs } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { Container } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { Menu } from '@material-ui/core'
import { useStyles } from './TicketView.style.js'
import SwipeableViews from 'react-swipeable-views'
import Navbar from '../Navbar'

const TicketsView = () => {
  const classes = useStyles()
  const {
    tickets,
    theme,
    value,
    handleChange,
    handleChangeIndex,
    handleMenu,
    anchorEl,
    handleCloseSort,
    handleSorting,
    userTickets,
    adminTickets
  } = TicketsViewLogic()

  console.log(tickets && tickets.body)

  return (
    <>
      <Navbar />
      <Container
        maxWidth="xl"
        style={{
          backgroundColor: '#FAFAFA',
          height: '100vh',
          width: '85%',
          paddingTop: '1rem'
        }}
      >
        <Typography align="center" variant="h4">
          TICKETS
        </Typography>
        <Button
          variant="contained"
          size="medium"
          onClick={handleMenu}
          style={{ fontSize: '17px' }}
        >
          Sort
        </Button>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          open={Boolean(anchorEl)}
          onClose={handleCloseSort}
        >
          <MenuItem onClick={() => handleSorting('priorityAsc')}>
            Priority (High to Low)
          </MenuItem>
          <MenuItem onClick={() => handleSorting('priorityDesc')}>
            Priority (Low to High)
          </MenuItem>
          <MenuItem onClick={() => handleSorting('timeAsc')}>
            Time Ascending
          </MenuItem>
          <MenuItem onClick={() => handleSorting('timeDesc')}>
            Time Descending
          </MenuItem>
        </Menu>

        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            aria-label="Users List"
            selectionFollowsFocus
            centered
          >
            <Tab
              label="User opened Cases"
              {...a11yProps(0)}
              className={classes.itemBox}
            />
            <Tab
              label="Admin Opened Cases"
              {...a11yProps(1)}
              className={classes.itemBox}
            />
          </Tabs>

          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <div className={classes.tableContainer}>
                <TableTickets tickets={userTickets} />
              </div>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <div className={classes.tableContainer}>
                <TableTickets tickets={adminTickets} />
              </div>
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Container>
    </>
  )
}

export default TicketsView
