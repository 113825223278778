import { MuiThemeProvider, CssBaseline } from '@material-ui/core'
import Routes from './Routes'

import theme from './theme'

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Routes />
    </MuiThemeProvider>
  )
}

export default App
