import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingRight: '2.5rem',
    paddingLeft: '2.5rem',
    paddingBottom: '2rem'
  },
  card: {
    width: '400px',
    height: '200px',
    padding: '1rem',
    cursor: 'pointer',
    marginLeft: '2rem',
    marginRight: '2rem',
    color: '#24aadd'
    // '&:hover': {
    //     background: "#e7f1f9",
    //     border: "2px solid #aacdea"
    // },
  },
  inputHolder: {
    appearance: 'none',
    display: 'block',
    background: 'rgb(243 244 246)',
    border: '1px solid rgb(243 244 246)',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    paddingLeft: '0.5rem',
    width: '23rem',
    '&:focus': {
      outline: 'none',
      background: 'white',
      border: '1px solid rgb(156 163 175)'
    }
  }
}))
