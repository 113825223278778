import { useState } from 'react'
import { sendVerificationMail } from './helper'

import swal from 'sweetalert'

const ForgetPasswordLogic = () => {
  const [email, setEmail] = useState('')

  const submitHandler = async (e) => {
    e.preventDefault()
    await sendVerificationMail(email)
    swal('A email is sent with reset link', 'Check your email', 'success')
  }
  return { submitHandler, email, setEmail }
}

export default ForgetPasswordLogic
