import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSingleTicketDetals,
  changeSingleTicketStatus,
  getSingleTicketConversation,
  postConversation
} from '../../redux/actions/TicketRaisingAction'
import { useLocation } from 'react-router-dom'

export const CaseDiscussionLogic = () => {
  const [inputArea, setInputArea] = useState(false)
  const [textBoxConent, setTextBoxConent] = useState('')
  const [counter, setCounter] = useState(0)

  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  const query = useQuery()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSingleTicketDetals(query.get('ticketId')))
    // eslint-disable-next-line
  }, [dispatch, counter])

  useEffect(() => {
    dispatch(getSingleTicketConversation(query.get('ticketId')))
    // eslint-disable-next-line
  }, [dispatch, counter])

  const { singleTicket } = useSelector((state) => state.getSingleTicketDetals)
  const { conversation } = useSelector(
    (state) => state.getSingleTicketConversation
  )

  console.log('====================================')
  console.log(conversation && conversation.body)
  console.log('====================================')

  const textBoxHandler = () =>
    inputArea ? setInputArea(false) : setInputArea(true)

  function postConversationHandlere() {
    dispatch(
      postConversation(textBoxConent, 'IEMSecure', query.get('ticketId'), setCounter, counter)
    )
  }
  const statusHandler = () => {
    dispatch(changeSingleTicketStatus(query.get('ticketId'), setCounter, counter))
  }

  return {
    textBoxHandler,
    inputArea,
    setInputArea,
    singleTicket,
    statusHandler,
    conversation,
    textBoxConent,
    setTextBoxConent,
    postConversationHandlere
  }
}
