import { makeStyles } from '@material-ui/core/styles'
const drawerWidth = 270

export const useStyles = makeStyles((theme) => ({
  course: {
    flexGrow: 1,
    fontWeight: '690',
    color: '#6c63ff'
  },
  modalbtn: {
    cursor: 'pointer'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[9],
    padding: theme.spacing(2, 4, 3),
    height: '40vh',
    width: '35vw'
  },
  margin: {
    margin: '0.5rem 0'
  },
  btn: {
    margin: '0 2.1rem 0 0.1rem',
    color: 'white'
  },
  wordcount: {
    marginTop: theme.spacing(-5)
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#F0EEFF',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.08)'
  },

  title: {
    flexGrow: 1,
    fontWeight: 'bolder',
    alignItems: 'center'
  },

  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    padding: theme.spacing(9, 0),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    [theme.breakpoints.down('md')]: {
      width: drawerWidth
    }
  },

  menu: {
    width: '9rem'
  },

  menuButton: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  navLogo: {
    width: '7.1vw',
    marginLeft: '2vw',
    marginTop: '0.3vw',
    [theme.breakpoints.down('sm')]: {
      // width: "7.1vw"
    }
  },
  navCreditBtn: {
    fontFamily: "'ITC Avant Garde Gothic Std Bk','Montserrat', sans-serif",
    fontSize: '1vw',
    textTransform: 'none',
    color: '#5145FF',
    border: 'solid 3.3px transparent',
    backgroundImage: 'linear-gradient(90deg, #5045FF 6.83%, #9A3CFD 100%)',
    boxShadow: '2px 1000px 1px #F0EEFF inset',
    borderRadius: '68px',
    padding: '0.18rem 1rem 0.18rem 1.2rem'
  },
  navCreditDiv: {
    position: 'absolute',
    top: '3vw',
    width: '17vw',
    // height: "12vw",
    background: '#fff',
    zIndex: '10000'
  },
  navProfilePic: {
    width: '2vw',
    height: '2vw',
    borderRadius: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '9.5vw',
      height: '9.5vw'
    }
  },
  navProfileName: {
    fontSize: '1.3vw',
    fontWeight: '600',
    background: 'linear-gradient(90deg, #5145FF, #9A3CFD)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    marginRight: '1vw'
  }
}))
