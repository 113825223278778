import React from 'react'

import { useStyles } from './Notifications.style'

export default function Notifications({ action, date }) {
  const classes = useStyles()
  const statusDateTime = new Date(date)
  return (
    <>
      <div className={classes.notificationsDetails}>
        <p>{action}</p>
        <p style={{ marginRight: '10rem' }}>
          {statusDateTime.toLocaleString()}
        </p>
      </div>
      <hr />
    </>
  )
}
