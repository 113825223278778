import axios from 'axios'

const API = process.env.REACT_APP_API

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
}

export const sendVerificationMail = async (email) => {
  const body = {
    email: email
  }
  const { data } = await axios.post(`${API}/admin/email-verify`, body, config)
  return data
}
