import {
  Button,
  TextField,
  Paper,
  Container,
  Grid,
  Typography
} from '@material-ui/core'
import { useStyles } from '../auth.style'
import ForgetPasswordLogic from './ForgetPassword'

export default function ForgetPassword({ history }) {
  const classes = useStyles()
  const { submitHandler, email, setEmail } = ForgetPasswordLogic(history)

  return (
    <>
      <Container>
        <Grid container component="main" className={classes.root}>
          <Grid item xs={false} sm={false} md={3}>
            {' '}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            component={Paper}
            className={classes.paper}
          >
            <Typography component="h1" variant="h4">
              Forget Password
            </Typography>
            <br />
            <Typography component="h1" variant="h5" />
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={submitHandler}
              >
                Send Mail
              </Button>
            </form>
          </Grid>
          <Grid item xs={false} sm={false} md={3} />
        </Grid>
      </Container>
    </>
  )
}
