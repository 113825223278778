import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getActivityLogs } from '../../redux/actions/adminActivityLogsActions'

export const AdminActivityLogsLogic = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getActivityLogs())
  }, [dispatch])
  const { data } = useSelector((state) => state.getActivityLogs)

  return { data }
}
