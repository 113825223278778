import axios from 'axios'

const API = process.env.REACT_APP_API

const config = {
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${
      sessionStorage.getItem('token') || localStorage.getItem('token')
    }`
  }
}

// delete user
export const deleteUserHelper = async (id, uid, token) => {
  const url = `${API}/admin/user/${id}/delete`
  const admin = JSON.parse(localStorage.getItem('userData'))
  const adminName = admin.name

  const config = {
    headers: {
      'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
    },
    data: {
      uid: uid,
      adminName: adminName
    },
  }
  await axios.delete(url, config)
}

// delete many users
export const deleteManyUserHelper = async (ids, uids, usernames) => {
  const url = `${API}/admin/users/delete`
  const admin = JSON.parse(localStorage.getItem('userData'))
  const adminName = admin.name
  const body = {
    uids: uids,
    ids: ids,
    userNames: usernames,
    adminName: adminName
  }

  await axios.post(url, body, config)
}

// purchase history
export const allPurchaseHistoryHelper = async () => {
  const { data } = await axios.get(
    `${API}/payment/all-purchase-history`,
    config
  )
  return data
}
