import axios from "axios";



const url = `${process.env.REACT_APP_API}/admin`

const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${
        sessionStorage.getItem('token') || localStorage.getItem('token')
      }`
    }
  }



export const getDevUsers = async () => await axios.get(`${url}/dev-users`, config)
