import Paper from '@material-ui/core/Paper'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import React from 'react'
import { TicketsViewLogic } from '../TicketsView/TicketsView'
import { useHistory } from 'react-router-dom'
//id,name,sub
function OpenTicket() {
  const { tickets } = TicketsViewLogic()

  const history = useHistory()
  tickets &&
    tickets.body.sort((a, b) => {
      return b.createdAt - a.createdAt
    })

  const columns = [
    { id: 'caseid', label: 'Case ID', minWidth: 100 },
    { id: 'name', label: 'Name', minWidth: 140 },
    { id: 'subject', label: 'Subject', minWidth: 170 },
    {
      id: 'category',
      label: 'Category',
      minWidth: 100,
      align: 'left'
    },
    {
      id: 'priority',
      label: 'Priority',
      minWidth: 100,
      align: 'center'
    },
    {
      id: 'createdat',
      label: 'Created At',
      minWidth: 100,
      align: 'center'
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 100,
      align: 'center'
    }
  ]

  function createData(
    caseid,
    name,
    subject,
    category,
    priority,
    createdat,
    status,
    ticketId
  ) {
    return {
      caseid,
      name,
      subject,
      category,
      priority,
      createdat,
      status,
      ticketId
    }
  }
  let rows = []
  let priority = 'General'
  function tableContent(list) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].status) {
        let status = 'Open'
        if (list[i].priority === 1) priority = 'Most priority'
        else if (list[i].priority === 2) priority = 'Medium priority'
        else if (list[i].priority === 2) priority = 'Less priority'
        else if (list[i].priority === 2) priority = 'Least priority'
        else priority = 'General'
        rows.push(
          createData(
            list[i].caseId,
            list[i].user.name,
            list[i].subject.substring(0, 16),
            list[i].category,
            priority,
            list[i].createdAt.toString().substring(0, 10),
            status,
            list[i]._id
          )
        )
      }
    }
  }
  return (
    <>
      <Paper
        style={{
          borderRadius: '0.8rem',
          padding: '0.5rem',
          boxShadow: '1px 2px 2px #999'
        }}
      >
        {tickets && tableContent(tickets.body)}
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow style={{ borderRadius: '0.8rem' }}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: '700',
                      fontSize: '17px'
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.caseid}
                  >
                    {columns.map((column) => {
                      return (
                        <>
                          {column.id === 'subject' ? (
                            <TableCell
                              style={{
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                history.push(
                                  `/support/?ticketId=${row.ticketId}`
                                )
                              }}
                              key={column.id}
                              align={column.align}
                            >
                              {row[column.id]}
                            </TableCell>
                          ) : (
                            <TableCell
                              style={{
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                history.push(
                                  `/support/?ticketId=${row.ticketId}`
                                )
                              }}
                              key={column.id}
                              align={column.align}
                            >
                              {row[column.id]}
                            </TableCell>
                          )}
                        </>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

export default OpenTicket
